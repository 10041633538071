<template>
	<div class="home">
		<x-header back custom-back @back="$router.push(`/manager/group`)">项目信息</x-header>
		<x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
			<bounce-loading></bounce-loading>
		</x-content>
		<x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
			<x-card v-if="groupData" style="margin-bottom:15px;">
					<div class="Group__Info">
						<!-- <el-avatar :size="90" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar> -->
						<svg t="1668739955620" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16112" width="128" height="128"><path d="M508.9 467.1h3c69.3-0.8 125.3-56.1 125.3-124.1v-41.4c0-68.6-56.8-124.2-126.8-124.2S383.6 233 383.6 301.6V343c0 68 56 123.3 125.3 124.1z m246 18.1c45 0 81.5-34.8 81.5-77.6v-26c0-42.9-36.5-77.6-81.5-77.6s-81.5 34.8-81.5 77.6v25.9c0 42.9 36.5 77.7 81.5 77.7z m-489 0c45 0 81.5-34.8 81.5-77.6v-26c0-42.9-36.5-77.6-81.5-77.6s-81.5 34.8-81.5 77.6v25.9c-0.1 42.9 36.4 77.7 81.5 77.7z m-117.6 79c-21.4 10.9-36.3 35.3-36.3 63.9V660c0 21.1 12.2 38.7 28.9 44.5 26 6.8 52.6 11.3 79.8 13.8V662c0-50.3 18.2-94.4 45.9-122.3h-0.7c-41.4-0.2-81.1 8.6-117.6 24.5z m724.5 0.1c-36.6-16-76.3-24.8-117.9-24.8h-0.6c27.8 28 45.9 72.1 45.9 122.3v56.4c27.1-2.5 53.8-7 79.6-13.8 16.7-5.7 29.1-23.4 29.1-44.6v-31.6c0-28.6-14.9-52.9-36.1-63.9z m-182.2-3.2c-56-25.7-116.7-39.8-180.2-39.8-63.4 0-123.9 14.1-179.8 39.6C297.9 578.2 275 617.3 275 663v51c0 33.8 18.7 62.1 44.2 71.3 61.6 16.7 125.5 25.9 191.3 25.9 65.7 0 129.5-9 191-25.8 25.7-9.1 44.4-37.5 44.4-71.3v-51c0-45.6-22.8-84.6-55.3-102z" p-id="16113" fill="#1296db"></path></svg>
						<div class="Group__InfoAll">
							<div class="Group__Name">{{groupData['groupName']}}</div>
							<fieldset class="Group__Users" ref="ResourceDetail_invitations" v-if="groupUsers.length > 0">
								<legend>项目成员({{groupUserTotal}})</legend>
								<div class="invitation_warp" ref="invitation_warp">
									<div v-for="(item,index) in groupUsers" class="ResourceDetail__author" :key="'invitationUsers' + index" style="float:left;padding:10px 10px;">
										<div class="AuthorInfo">
											<avatar :size="30" :url="item.user.avatar"></avatar>
											&nbsp;&nbsp;{{item.userName}}<span style="font-size: 13px;" v-if="GROUP_ROLE.LEADER == item.role || GROUP_ROLE.MANAGER == item.role">({{SHOW_GROUP_ROLE[item.role]}})</span>
										</div>
									</div>
								</div>
								<div class="read_more" v-if="isShowReadMore" @click="readMore">{{readMoreStatus?'收起':'展开'}}</div>
							</fieldset>
						</div>
					</div>
		</x-card>
			<div class="Application__Card--warp">
				<x-card class="Application__Card--item"  v-if="IS_LEADER" shadow="hover" @click.native="$router.push('/manager/groupUser/' + groupId)">
					<div class="Application__Card">
						<i class="iconfont">&#xe609;</i>
						<span>成员管理</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item"  shadow="hover" @click.native="$router.push('/manager/note/' + groupId)">
					<div class="Application__Card">
						<i class="iconfont">&#xe608;</i>
						<span>记录本管理</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item"  v-if="IS_LEADER || IS_MANAGER" shadow="hover" @click.native="$router.push('/manager/template/' + groupId)">
					<div class="Application__Card">
						<i class="iconfont">&#xe627;</i>
						<span>模板管理</span>
					</div>
				</x-card>
<!--				<x-card class="Application__Card&#45;&#45;item" disabled  v-if="IS_LEADER || IS_MANAGER" shadow="hover">-->
<!--					<div class="Application__Card">-->
<!--						<i class="iconfont">&#xe775;</i>-->
<!--						<span>实验管理</span>-->
<!--					</div>-->
<!--				</x-card>-->
				<x-card class="Application__Card--item"  v-if="IS_LEADER" shadow="hover">
					<div class="Application__Card" @click="reviewSet" >
						<i class="iconfont">&#xeaa1;</i>
						<span>复核设置</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item" v-if="IS_LEADER" shadow="hover">
					<div class="Application__Card" @click="shareSet" >
						<i class="iconfont">&#xe610;</i>
						<span>分享设置</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item" v-if="IS_LEADER || IS_MANAGER" shadow="hover" @click.native="$router.push('/manager/experiment/' + groupId+'_group')">
					<div class="Application__Card">
						<i class="el-icon-notebook-2"></i>
						<span>项目下的实验</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item" shadow="hover" @click.native="$router.push('/manager/experiment/share/' + groupId)">
					<div class="Application__Card">
						<i class="iconfont el-icon-share"></i>
						<span>分享给项目的实验</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item" disabled  v-if="IS_LEADER || IS_MANAGER" shadow="hover">
					<div class="Application__Card">
						<i class="iconfont">&#xe653;</i>
						<span>仪器管理</span>
					</div>
				</x-card>
				<x-card class="Application__Card--item" disabled  v-if="IS_LEADER || IS_MANAGER" shadow="hover">
					<div class="Application__Card">
						<i class="iconfont">&#xe61f;</i>
						<span>审计</span>
					</div>
				</x-card>
<!--				<x-card class="Application__Card&#45;&#45;item" disabled  v-if="IS_USER"  shadow="hover" @click.native="$router.push('/manager/note/' + groupId)">-->
<!--					<div class="Application__Card">-->
<!--						<i class="iconfont">&#xe608;</i>-->
<!--						<span>复核</span>-->
<!--					</div>-->
<!--				</x-card>-->
<!--				<x-card class="Application__Card&#45;&#45;item" disabled  v-if="IS_USER"  shadow="hover" @click.native="$router.push('/manager/note/' + groupId)">-->
<!--					<div class="Application__Card">-->
<!--						<i class="iconfont">&#xe608;</i>-->
<!--						<span>分析</span>-->
<!--					</div>-->
<!--				</x-card>-->
			</div>
			<app-dialog  ref="reviewDialog" dialogTitle="复核设置" @hide="hideReviewSet" originTitle staticDialog width="600px">
				<div style="display: flex;flex-direction: column;">
					<template v-if="groupData.reviewUserId">
						<span style="margin-bottom: 10px;">复核人：
							
							<!-- {{groupData.reviewUserName}}
							<el-link style="margin-left:10px;" :underline="true" type="primary">修改复核人</el-link> -->
						</span>
						<el-checkbox-group 
							v-model="selectTableUser"
							>
							<el-checkbox v-for="group in groupUsers" :label="group" :key="'fhr'+group.id">{{group.userName}}</el-checkbox>
						</el-checkbox-group>
					</template>
					<template v-else>
						<span  v-if="this.selectTableUser.length>0">复核人：
							{{this.selectTableUser.map(e => {return e.userName}).join("；")}}
						</span>
					</template>
					<div style="display: flex;flex-direction: column;margin-top: 20px;">
						<!-- <el-checkbox  style="margin-top:10px;" label="1" >复核签名时必须选择复核人</el-checkbox> -->
						<el-checkbox v-model="emailToReviewUser"  style="margin-top:10px;">邮件提醒复核人待复核的实验</el-checkbox>
						<el-checkbox v-model="emailToReviewResult"  style="margin-top:10px;">邮件提醒被复核人实验的复核结果</el-checkbox>
						<el-checkbox v-model="noEdit"  style="margin-top:10px;">实验复核通过后不允许修改</el-checkbox>
					</div>
					<div class="setTooltip">
						<i class="el-icon-info"></i> 该设置仅对该项目下的实验有效
					</div>
				</div>
				<div class="save_btn_warp">
					<el-button type="primary" style="width:200px;" size="medium" @click="saveReviewSet">确定</el-button>
				</div>
			</app-dialog>
			<app-dialog  ref="shareDialog" dialogTitle="分享设置" @hide="hideShareSet" originTitle staticDialog width="600px">
				<div style="display: flex;flex-direction: column;">
					<template v-if="groupData.shareUser">
						<span style="margin-bottom: 10px;">默认分享用户：
						</span>
						<el-checkbox-group 
							v-model="selectTableUser"
							>
							<el-checkbox v-for="group in groupUsers" :label="group" :key="'fhr'+group.id">{{group.userName}}</el-checkbox>
						</el-checkbox-group>
					</template>
					<template v-else>
						<span v-if="this.selectTableUser.length>0">默认分享用户：
							{{this.selectTableUser.map(e => {return e.userName}).join("；")}}
						</span>
					</template> 
					<div style="margin-top:15px">
						<span>默认分享项目</span>
						<div style="display: flex;flex-direction: column;">
							<el-checkbox v-model="defaultShareGroup"  style="margin-top:10px;">{{groupData.groupName}}</el-checkbox>
						</div>
					</div>
					<div style="margin-top:15px">
						<span>分享权限设置</span>
						<div style="display: flex;flex-direction: column;">
							<el-checkbox v-model="shareAppointPeople"  style="margin-top:10px;">允许分享给指定用户</el-checkbox>
							<el-checkbox v-model="shareAddGroup"  style="margin-top:10px;">允许分享到加入的项目</el-checkbox>
							<el-checkbox v-model="shareOtherGroup"  style="margin-top:10px;">允许分享到其他项目</el-checkbox>
						</div>
					</div>
					<div class="setTooltip">
						提示：该设置仅对该项目下的实验有效
					</div>
				</div>
				<div class="save_btn_warp">
					<el-button type="primary" style="width:200px;" size="medium" @click="saveShareSet">确定</el-button>
				</div>
			</app-dialog>
		</x-content>
	</div>
</template>

<script>
import { GetGroupUser,GetGroupById,UpdateGroup,GetAllGroupUser} from "../../service/user"
import {GroupMixins} from "@/mixins/groupIndex";

export default {
	name: "GroupDetail",
	mixins:[GroupMixins],
	data(){
		return {
			loading:false,
			groupData:{},
			groupUsers:[],
			groupUserTotal:0,
			isShowReadMore:false,
			readMoreStatus:false,

			selectTableUser:[],
			noEdit:false,
			emailToReviewUser:false,
			emailToReviewResult:false,
			shareAppointPeople:false,
			shareAddGroup:false,
			shareOtherGroup:false,
			defaultShareGroup:false,
		}
	},
	created() {
		this.getGroupById();
		this.getAllUser();
	},
	methods:{
		getGroupById(){
			GetGroupById(this.groupId).then(e => {
				let data = this.APIParse(e);
				this.groupData = data;
			})
		},
		getAllUser(){
			this.loading = true;
			GetGroupUser(this.groupId,'',1,100).then(e => {
				let data = this.APIParse(e)
				this.groupUsers = data?.records?.sort((a,b) => {
					let aIndex = 0;
					let bIndex = 0;
					if(a?.role === this.GROUP_ROLE.LEADER){
						aIndex = 2
					}else if(a?.role === this.GROUP_ROLE.MANAGER){
						aIndex = 1
					}
					if(b?.role === this.GROUP_ROLE.LEADER){
						bIndex = 2
					}else if(b?.role === this.GROUP_ROLE.MANAGER){
						bIndex = 1
					}
					return bIndex - aIndex
				}) || []
				this.groupUserTotal = data?.total || 0
				this.$nextTick(() => {
					setTimeout(() => {
						var height = window.getComputedStyle(this.$refs['invitation_warp']).height;
						height = parseInt(height.substring(0,height.indexOf('px')));
						if(height > 54){
							this.isShowReadMore = true;
						}else{
							this.isShowReadMore = false;
						}
					},1000)
				});
			}).finally(() => {
				this.loading = false;
			})
		},
		readMore(){
			this.$nextTick(() => {
				this.readMoreStatus = !this.readMoreStatus;
				if(this.readMoreStatus){
					this.$refs['ResourceDetail_invitations'].style.height = 'auto';
				}else{
					this.$refs['ResourceDetail_invitations'].style.height = '74px';
				}
			})
		},
		reviewSet(){
			this.$refs['reviewDialog'].show()  
			if(this.groupData.reviewUserId){
				if("1" == this.groupData.emailToReviewUser){
					this.emailToReviewUser = true
				}else{
					this.emailToReviewUser = false
				}
				if("1" == this.groupData.emailToReviewResult){
					this.emailToReviewResult = true
				}else{
					this.emailToReviewResult = false
				}
				if("1" == this.groupData.noEdit){
					this.noEdit = true
				}else{
					this.noEdit = false
				}
				this.selectTableUser = this.groupUsers.filter((e) => {return e.userId==this.groupData.reviewUserId})
			}
		},
		handleSelectionChange(val){
			this.selectTableUser = val;
		},
		tipSelectTableUser(){
			if(this.selectTableUser.length==0){
				this.$message.warning("请选择复核人！")
				return false
			}
			if(this.selectTableUser.length>1){
				this.$message.warning("只能选择一个复核人！")
				return false
			}
			return true;
		},
		saveReviewSet(){
			if(this.groupData.reviewUserId){
				if(!this.tipSelectTableUser()){
					return;
				}
			}
			let param = {"id":this.selectTableUser[0].groupId,"reviewUserId":this.selectTableUser[0].userId,"reviewUserName":this.selectTableUser[0].userName}
			if(this.emailToReviewUser){
				param.emailToReviewUser = "1"
			}else{
				param.emailToReviewUser = "0"
			}
			if(this.emailToReviewResult){
				param.emailToReviewResult = "1"
			}else{
				param.emailToReviewResult = "0"
			}
			if(this.noEdit){
				param.noEdit = "1"
			}else{
				param.noEdit = "0"
			}
			UpdateGroup(param).then(e => {
				let data = this.APIParse(e);
				if(data){
					this.$message.success("设置成功")
					this.getGroupById()
					this.hideReviewSet()
				}
			}).catch(e => {
				this.$message.error(e.message)
			})
		},
		hideReviewSet(){
			this.$refs['reviewDialog'].hide()
			this.emailToReviewUser = false
			this.emailToReviewResult =false
			this.noEdit = false
			this.selectTableUser = []
		},
		shareSet(){
			this.$refs['shareDialog'].show()  
			if(this.groupData.shareUser){
				if("1" == this.groupData.shareAppointPeople){
					this.shareAppointPeople = true
				}else{
					this.shareAppointPeople = false
				}
				if("1" == this.groupData.shareAddGroup){
					this.shareAddGroup = true
				}else{
					this.shareAddGroup = false
				}
				if("1" == this.groupData.shareOtherGroup){
					this.shareOtherGroup = true
				}else{
					this.shareOtherGroup = false
				}
				if("1" == this.groupData.defaultShareGroup){
					this.defaultShareGroup = true
				}else{
					this.defaultShareGroup = false
				}
				let shareUser = JSON.parse(this.groupData.shareUser);
				let shareIdList = shareUser.map(e => {return e.id})
				this.selectTableUser = this.groupUsers.filter((e) => {return shareIdList.includes(e.userId)})
			}
		},
		saveShareSet(){
			// if(this.groupData.reviewUserId){
			//     this.tipSelectTableUser()
			// }
			let shareUser = [];
			this.selectTableUser.forEach(e => {
				shareUser.push({
					name:e.userName,
					id:e.userId
				})
			})
			let param = {"id":this.selectTableUser[0].groupId,"shareUser":JSON.stringify(shareUser)}
			if(this.shareAppointPeople){
				param.shareAppointPeople = "1"
			}else{
				param.shareAppointPeople = "0"
			}
			if(this.shareAddGroup){
				param.shareAddGroup = "1"
			}else{
				param.shareAddGroup = "0"
			}
			if(this.shareOtherGroup){
				param.shareOtherGroup = "1"
			}else{
				param.shareOtherGroup = "0"
			}
			if(this.defaultShareGroup){
				param.defaultShareGroup = "1"
			}else{
				param.defaultShareGroup = "0"
			}
			UpdateGroup(param).then(e => {
				let data = this.APIParse(e);
				if(data){
					this.$message.success("设置成功")
					this.getGroupById()
					this.hideShareSet()
				}
			}).catch(e => {
				this.$message.error(e.message)
			})
		},
		hideShareSet(){
			this.$refs['shareDialog'].hide()
			this.shareAppointPeople = false
			this.shareAddGroup =false
			this.shareOtherGroup = false
			this.defaultShareGroup = false
			this.selectTableUser = []
		},

	}
}
</script>

<style scoped lang="scss">
	@import "../../components/base/ui/style/var.scss";
	.home{width: 100%;height: 100%;overflow: auto;}
	.Group__Info{
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		.Group__InfoAll{
			padding: 16px;
			flex: 1;
			height: auto;
			.Group__Name{
				font-size: 16px;
				font-weight: 600;
			}
			.Group__Users{
				width:99%;
				height:74px;
				overflow: hidden;
				margin-top: 16px;
				position:relative;
				border:1px dashed $--color-primary;
				legend{font-weight: bold;padding: 8px;background: white;}
				.read_more{
					width:60px;
					float:left;
					margin-top:8px;
					color:$--color-primary;
					cursor: pointer;
				}
				.read_more:hover{
					opacity: .7;
				}
				.invitation_warp{
					width:calc(100% - 60px);
					height: auto;
					overflow: hidden;
					float:left;
					.ResourceDetail__author {
						color: $--color-text-regular;
						font-size: 15px;
						font-weight: bold;
						.AuthorInfo {
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							margin-top: -8px;
							padding-bottom: 8px;
						}
					}
				}
			}
		}
	}
	.Application__Card--warp{
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}
	.Application__Card--item{
		width: 12.5%;
		position: relative;

	}
	.Application__Card--item[disabled]{
		opacity: .5;
		//&:before{
		//	position: absolute;
		//	width: 100%;
		//	height: 100%;
		//	top: 0px;
		//	left: 0px;
		//	background: rgba(0,0,0,.5);
		//	content: '';
		//	z-index: 10;
		//}
	}
	.Application__Card{
		width: 100%;
		height: 100px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		i{
			font-size: 40px;
			margin-bottom: 16px;
			color: $--color-text-secondary;
		}
		span{
			color: $--color-text-regular;
		}
		&:hover i,&:hover span{
			color: $--color-primary;
		}
	}
	.save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
	.setTooltip{
        margin-top: 30px;
        color:#409eff
    }
</style>